import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import SwalMessageService from '../SwalMessageService';
import GeneralService from '../interfaces/GeneralService';
import MessageService from '../interfaces/MessageService';
import FilterWareHouseAdmin from '@/models/warehouseadmin/FilterWareHouseAdmin';
import WareHouseAdminRow from '@/models/warehouseadmin/WareHouseAdminRows';

export default class WareHouseAdminService implements GeneralService{
    apiController = "/Warehouses";
    messageService: MessageService = new SwalMessageService();
    
    async getTableWareHouse(paginator: PaginationRequest, filter: FilterWareHouseAdmin): Promise<AxiosResponse<PaginationResponse<WareHouseAdminRow>>> {
        return  ApiService.get<PaginationResponse<WareHouseAdminRow>>(this.apiController, 
            `GetAllWareProductStock?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.WarehouseId != undefined ? `&Criteria.WarehouseId=${filter.WarehouseId}` : '')
            + (filter.FamilyId != undefined ? `&Criteria.FilterProduct.FamilyId=${filter.FamilyId}` : '')
            + (filter.SubFamilyId != undefined ? `&Criteria.FilterProduct.SubFamilyId=${filter.SubFamilyId}` : '')
            + (filter.ColorId != undefined ? `&Criteria.FilterProduct.ColorId=${filter.ColorId}` : '')
            + (filter.SupplierId != undefined ? `&Criteria.FilterProduct.SupplierId=${filter.SupplierId}` : '')
            + (filter.Name != undefined ? `&Criteria.FilterProduct.Name=${filter.Name}` : '')
            + (filter.Description != undefined ? `&Criteria.FilterProduct.Description=${filter.Description}` : '')
           
        ).then( resp => {
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }
}