import PaginationRequest from "@/models/general/PaginationRequest";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '@/store';
import Modules from "@/store/enums/Modules";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Actions } from "@/store/commons/ActionsTable";
import SaleRow from "@/models/sales/SaleRows";
import WareHouseAdminRow from "@/models/warehouseadmin/WareHouseAdminRows";
import TableWareHouseAdminState from "../models/TableSaleState";
import FilterWareHouseAdmin from "@/models/warehouseadmin/FilterWareHouseAdmin";
import { Mutations } from "@/store/commons/MutationsTable";
import WareHouseAdminService from "@/core/services/warehouseadmin/WareHouseAdminService";

@Module({dynamic: true, store, namespaced: true, name: Modules.TableWareHouseAdminModule})
export default class TableWareHouseAdminModule extends VuexModule implements TableWareHouseAdminState {
    records = [] as WareHouseAdminRow[];
    
    totalRecords = 0;
    
    filters = {
        WarehouseId: '',
        ColorId: '',
        Description: '',
        FamilyId: '',
        FilterProductId: '',
        Name: '',
        SubFamilyId: '',
        SupplierId: '',
    } as FilterWareHouseAdmin;

    pagination = {
        currentPage: 1
        , pageSize: 10
    } as PaginationRequest;

    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: WareHouseAdminRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const resp = (await service.getTableWareHouse(this.pagination, this.filters)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             {
                 data: [] as WareHouseAdminRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
        console.log(resp)
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }

    
    @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
   

    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
}

 const service =  new WareHouseAdminService() as WareHouseAdminService;
