
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue"
import { translate } from "@/core/plugins/i18n";
import * as Validations from "yup";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { getModule } from "vuex-module-decorators";
import TableWareHouseAdminModule from "@/store/modules/warehouseadmin/modules";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { debounce } from "lodash";
import { Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";

export default defineComponent({
    components:{
        ModuleMaster,
        SelectOption,
        Field,
        Datatable,
        Datarow,
        Form
    },
    setup() {
        const messageService = new SwalMessageService();
        let warehouses = ref([]);
        let families = ref([]);
        let subFamilies = ref([]);
        let colors = ref([]);
        let suppliers = ref([]);
        const moduleTable = getModule(TableWareHouseAdminModule);

        const warehouseOptions = computed(() => warehouses.value);
        const familyOptions = computed(() => families.value);
        const subFamilyOptions = computed(() => subFamilies.value);
        const colorOptions = computed(() => colors.value);
        const supplierOptions = computed(() => suppliers.value);

        onBeforeMount(async() => {
            await getComboOptions("Warehouses", warehouses);
            await getComboOptions("Families", families);
            await getComboOptions("Colors", colors);
            await getComboOptions("Suppliers", suppliers);

            // moduleTable.SEARCH_TABLE_DATA();
        });

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeFamily = async(famId) => {
            if(famId) {
                subFamilies.value = [];
                // modalForm.value.$refs.form.setFieldValue("SubfamilyId", "");
                await getComboOptions("subfamilies", subFamilies, famId);
            }
        }

       

        //Propiedades
        const validationSchema = Validations.object().shape({
            warehouseId: Validations.string().nullable().default(null).label("warehouseId"),
            FamilyId: Validations.string().nullable().default(null).label("FamilyId"),
            SubfamilyId: Validations.string().nullable().default(null).label("SubfamilyId"),
            Name: Validations.string().nullable().default(null).label("Name"),
            ColorId: Validations.string().nullable().default(null).label("ColorId"),
            Description: Validations.string().nullable().default(null).label("Description"),
            SupplierId: Validations.string().nullable().default(null).label("SupplierId"),
        })
      
        //Schema
        /**
         * CONFIGURACIONES DE LA TABLA
         */
         const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "warehouseName", HeadLabel: "Almacén", Type: "text", VisibleInGrid: true },
            { PropName: "productName", HeadLabel: "Producto", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "U.M.", Type: "text", VisibleInGrid: true },
            { PropName: "inputs", HeadLabel: "Entrada", Type: "text", VisibleInGrid: true },            
            { PropName: "preCommittedInputs", HeadLabel: "Comprometido Entrada", Type: "text", VisibleInGrid: true },            
            { PropName: "outputs", HeadLabel: "Salida", Type: "text", VisibleInGrid: true },            
            { PropName: "preCommittedOutputs", HeadLabel: "Comprometido Salida", Type: "text", VisibleInGrid: true },            
            { PropName: "stock", HeadLabel: "Existencia", Type: "text", VisibleInGrid: true },            
            { PropName: "cost", HeadLabel: "Costo", Type: "moneda", VisibleInGrid: true },            
            { PropName: "lastCost", HeadLabel: "Ultimo costo", Type: "moneda", VisibleInGrid: true },            
            { PropName: "price", HeadLabel: "Precio", Type: "text", VisibleInGrid: false },            
            { PropName: "lastPrice", HeadLabel: "Ultimo Precio", Type: "moneda", VisibleInGrid: false },            
        ]);

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar articulo",
            titleNewCrudAction: "",
        });
       

        //METODOS
        const handleSearchWaterSource = (name: string) => {
           console.log("buscar")
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            console.log(action);
        };

        const filterNameChange = debounce((filterName: string) => {
            moduleTable.UPDATE_FILTERS({filterName});
        }, 550, {leading: false, trailing: true});


        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable);
        });

        const searchFilter = (data: any) => {
            moduleTable.UPDATE_FILTERS({
                WarehouseId: data.warehouseId,
                FilterProductId: "",
                Name:  data.Name,
                Description:  data.Description,
                FamilyId:  data.FamilyId,
                SubFamilyId:  data.SubfamilyId,
                ColorId:  data.ColorId,
                SupplierId:  data.SupplierId
            });
        }

        const records = computed(() => moduleTable.records);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const watersourceCombo = computed(() => [] ); //moduleTable.getwatersourceComboOptions

        onMounted(() => {
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.WAREHOUSE_ADMIN'), [translate('BREADCRUMB.MODULES')]);
        });
        
        return {

            labels
            , validationSchema
            , headers
            , renderRows
            , records
            , totalRecords
            , sizePage
            , filterNameChange
            , watersourceCombo
            , warehouses
            , warehouseOptions
            ,subFamilies
            ,families
            ,subFamilyOptions
            ,familyOptions
            ,suppliers
            ,colors
            ,supplierOptions
            ,colorOptions

            ,handleSearchWaterSource
            ,handleCrudAction
            ,searchFilter
            ,getComboOptions
            ,onChangeFamily
        }
    }
})


